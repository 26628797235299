import React, {Component} from 'react';

class Services extends Component {
    render() {
        return(
            <section className='page'>
                <div className='bumper'></div>
                <div className='header'>
                    <div className='banner-container'>
                        <div className='main-container'>
                        <div className='content-container'>
                            <h1 className='header-text'>Our services</h1>
                            <h3 className='subheader-text'>Find out more about the work we do</h3>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='main-container'>
                    <div className='content-container'>
                        <h1>Under construction</h1>
                    </div>
                </div>
            </section>
        )
    }
}

export default Services