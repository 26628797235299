import React, {Component} from 'react';
import Logo from '../images/Alex KJ logos-02.svg'
import { MenuItems } from './MenuItems';


class Header extends Component {
    render(){
        return(

            <div className='header-container' style={{paddingTop:0}}>
                <div className='logo-header'>
                    <div className='content-container'>
                        <a href='/'>
                            <img src={Logo} className="logo" alt='Alex Kitchens and Joinery Logo'></img>
                        </a>
                        <ul className='menu-items'>
                        {MenuItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <CustomLink id="navbar-button" className={item.className} href={item.url}>
                                            {item.title}
                                        </CustomLink>
                                    </li>
                                )
                            })}

                        <a href='/contact' className="button-quotation">REQUEST A QUOTATION</a>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

function CustomLink({ href, children, ...props }) {
    const path = window.location.pathname

    return (
        <li className={path === href ? "active" : ""}>
            <a href={href}>{children}</a>
        </li>
    )
}

export default Header