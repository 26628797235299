import React, { Component } from 'react';
import { SimpleSlider } from './Testimonials';


class Home extends Component {
    render() {
        return (<>
            <section className='page'>
                <div className='bumper'></div>

                <div class="hero-image">
                    <div className='main-container'>
                        <div className='content-container'>
                            <div className='hero-sub-images'>
                                <div className="hero-text"><h1>Transform your home with our custom joinery solutions</h1>
                                <a href='/contact' className="button-quotation" id='home-cta-button'>GET A FREE QUOTE</a>
</div>
                                
                                <img className='hero-card' src="./images/image_3.jpg" alt="A spacious kitchen with a large island and plenty of storage space."></img>
                                <img className='hero-card' src="./images/image_2.jpg" alt='A modern kitchen with a granite countertop.'></img>
                                <img className='hero-card' src="./images/image_4.jpg" alt='A beautifully renovated bathroom.'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-container'>
                    <div className='content-container'>
                        <div className='hero-sub-images' id='home-description'>
                            <p className='home-description'>At Alex K&J Building Specialists, we are a Northwich, Cheshire-based team of skilled craftsmen specializing in kitchen and joinery fittings in the north west of England. We pride ourselves on delivering top-quality workmanship and excellent customer service. From designing and installing custom kitchens to fitting doors and guttering, we have the expertise to handle all of your joinery needs.                            {/* <br></br> */}
                            {/* <a href='/contact' className="button-quotation" id='home-cta-button'>REQUEST A FREE QUOTATION</a> */}
                            </p>
                        </div>
                    </div>

                </div>
                <div className='main-container'>
                <div className='content-container' id='slideshow-container'>
                        <ul className="slideshow">
                            <li><span></span></li>
                            <li><span></span></li>
                            <li><span></span></li>
                            <li><span></span></li>
                            {/* <li><span></span></li> */}
                        </ul>
                        <div className='slideshow-text-container'>
                        <h1>QUALITY IN MIND</h1>
                        <p className='slideshow-text'>We use only the finest materials and the latest techniques to ensure that your project is completed to the highest standard. Contact us today to schedule a consultation and bring your dream ideas to life.</p>
                        </div>
                    </div>
                    
                </div>
            </section>
            <SimpleSlider />
            </>
        )
    }
}

export default Home
