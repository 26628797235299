import React from "react";
import Slider from "react-slick";
// import "../slick.css";
// import "../slick-theme.css";

export function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className='main-container'>
      <div className='content-container'>
        <div className='testimonial-container'>
      <Slider {...settings}>
        <div className="testimonial-card">
          <h4>"I recently had the pleasure of working with this joinery for a custom cabinet project in my kitchen. The team was professional, efficient, and did an excellent job. The finished product is beautiful and exactly what I wanted. I am extremely satisfied with the work and highly recommend this business to anyone in need of top-quality joinery services."</h4>
          <p className="testimonial-author">- Joseph</p>
        </div>
        <div className="testimonial-card">
          <h4>"I contacted Alex K&J Building Specialists to install new kitchen cabinets in my home and the job was completed to perfection. I received a response very quickly and the quality of the work itself was outstanding. I highly recommend this business to anyone in need of expert joinery services."</h4>
          <p className="testimonial-author">- Carl</p>
        </div>
        <div className="testimonial-card">
          <h4>"My home desperately needed the guttering to be replaced. A friend had recommended Alex K&J Building Specialists to me, and the service I had received from the team was fantastic. They made quick work of the job and guttering on the exterior of my home looks better than ever!"</h4>
          <p className="testimonial-author">- Amy</p>

        </div>

      </Slider>
      </div>
      </div>
    </div>
  );
}