export const MenuItems = [
    {
        title: 'HOME',
        url: "/",
        className: 'navbar-links'
    },
    // {
    //     title: 'Services',
    //     url: "/services",
    //     className: 'navbar-links'
    // },
    // {
    //     title: 'About',
    //     url: "/about",
    //     className: 'navbar-links'
    // },
    {
        title: 'CONTACT',
        url: "/contact",
        className: 'navbar-links'
    }
]