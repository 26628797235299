import React, { Component } from 'react';
import { HiOutlineLocationMarker } from "react-icons/hi"
import { HiOutlineMail } from "react-icons/hi"
import { HiOutlinePhone } from "react-icons/hi"


class Contact extends Component {
    render() {
        return (
            <section className='page'>
                <div className='bumper'></div>
                <div className='header'>
                    <div className='banner-container'>
                        <div className='main-container'>
                        <div className='content-container'>
                            <h1 className='header-text'>Contact Us</h1>
                            <h3 className='subheader-text'>Get in touch to receive a free quotation</h3>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='main-container'>
                    <div className='content-container'>
                        <p className='contact-p' style={{ paddingTop: 20 }}>If you would like to discuss your ideas with us and receive a no obligation quote, please complete the form below. A member of our team will be in touch to discuss your project in more detail.</p>
                        <form className="contact-form" action="https://formsubmit.co/alexkitchensandjoinery@yahoo.com" method="POST">
                            <input type="text" className='_honey'></input>
                            <label for="name">Your Name <span className='necessary-field'>*</span> </label>
                            <input type="text" className="field" name="Name" placeholder="Your name" required></input>
                            <label for="email">Email Address <span className='necessary-field'>*</span></label>
                            <input type="email" className="field" name="Email" placeholder="Your email address" required></input>
                            <label for="message">Message <span className='necessary-field'>*</span></label>
                            <textarea className="field" nameName="Comment" placeholder="Your message" required></textarea>
                            <button className="button-5">Send</button>
                        </form>

                       
                        <ul className='contact-details-list'>
                        <h2 className='contact-header'>Contact Details</h2>
                            <li>
                                <HiOutlinePhone className='contact-icon' /> <a href="tel:07712257068">07712 257 068</a>
                            </li>
                            <li>
                                <HiOutlineMail className='contact-icon' /> <a href='mailto:alexkitchensandjoinery@yahoo.com'>alexkitchensandjoinery@yahoo.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contact