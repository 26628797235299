import React, { Component } from 'react';
import { MenuItems } from './MenuItems';

class Navbar extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (

            <nav className="nav">
                <div>
                    <div className='container2'>
                        <ul onClick={this.handleClick} className={this.state.clicked ? "navbar-menu-active" : "navbar-menu"}>
                            {MenuItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <CustomLink id="navbar-button" className={item.className} href={item.url}>
                                            {item.title}
                                        </CustomLink>
                                    </li>
                                )
                            })}

                        </ul>

                    </div>

                </div>
                <div onClick={this.handleClick} className={this.state.clicked ? "hamburger-active" : "hamburger"}>
                    <div class="line1"></div>
                    <div class="line2"></div>
                    <div class="line3"></div>
                </div>
            </nav>
        )
    }
}


function CustomLink({ href, children, ...props }) {
    const path = window.location.pathname

    return (
        <li className={path === href ? "active" : ""}>
            <a href={href}>{children}</a>
        </li>
    )
}

export default Navbar