import React, {Component} from 'react';
import { AiFillFacebook } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import {IoLogoWhatsapp} from "react-icons/io"

class Footer extends Component {
    render(){
        return(
            <div className='footer'>
                <div>
                    <a href='https://www.facebook.com/AlexKitchensandJoinery' target="_blank"><AiFillFacebook className='footer-icon' /></a>
                    <a href="https://www.instagram.com/alexkitchensandjoinery/" target="_blank"><AiFillInstagram className='footer-icon' /></a>
                    <a href="https://api.whatsapp.com/send?phone=%2B447712257068&fbclid=IwAR03M4GdeZL1VH15-4m6cwAM2E6zz5QZBCSwz41F2YiH7fRWxrG-I0t3lQo" target="_blank"><IoLogoWhatsapp className='footer-icon' /></a>
                    <p>Alex Kitchens & Joinery LTD is a company registered in England and Wales with company number 13776895</p>
                </div>
            </div>
        )
    }
}

export default Footer