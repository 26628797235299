// import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Services from "./components/Services";
import About from "./components/About";
import Navbar from './components/Navbar';
import Header from './components/Header';
import { SimpleSlider } from "./components/Testimonials";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";


export const App = () => {
	return (
		<>

			<div className="App">
				<Header />
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/about" element={<About />} />
					<Route path="/services" element={<Services />} />
				</Routes>
				<Footer />
			</div>
		</>
	)
}

export default App

// PRIVACY POLICY?
// COOKIE POLICY?
// https://www.practiceweb.co.uk/knowledge/displaying-company-registration-information/